@import "../../styles/colors";

.alive {
  color: $tortoise_main;
}

.failure {
  color: $red_main;
}

.dashboardWrapper {
  display: flex;
  grid-gap: 72px;
  padding: 7vw 3vw;
}

.dashboardContent {
  display: flex;
  flex-direction: column;
  flex-grow: 5;
  grid-gap: 32px;

  > .header {
    display: flex;
    grid-gap: 8px;

    .logout {
      align-self: center;
      margin-left: auto;
      cursor: pointer;
    }

    h3 > img {
      display: none;
      vertical-align: middle;
    }
  }
}

.dashboardMainInfo {
  display: flex;
  justify-content: space-between;
  grid-gap: 32px;
  flex-wrap: wrap;
}

@mixin info() {
  height: fit-content;
  width: 380px;
  min-height: 130px;
  align-items: center;
  background: $content_background_passive;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 24px 32px;
}

.harvesterHealth {
  @include info;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.harvesterHealthStatus {
  display: flex;
  align-items: center;

  &Active {
    color: $tortoise_main;
  }

  &Failure {
    color: $red_main;
  }
}

.harvesterHealthLevel {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  margin-left: 12px;

  &Active {
    background: $tortoise_main;
  }

  &Failure {
    background: $red_main;
  }
}

.valueInfo {
  @include info;
  display: flex;
  grid-gap: 32px;
  &Column {
    flex-direction: column;
    grid-gap: 8px;
    padding: 16px 24px;
    > div {
      display: flex;
      grid-gap: 8px;
      align-items: center;

      button {
        display: flex;
        min-width: 140px;
        align-items: center;
        justify-content: center;
        background: $content_background_passive;
        border: 1px solid $border_main;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 8px 32px;
        cursor: pointer;

        &:nth-child(1) {
          background: $tortoise_main;
        }

        &:nth-child(2) {
          background: $red_main;
        }
      }
    }
  }
}

.btnWrapper {
  display: flex;
  align-items: center;
}

.saveBtn {
  width: 44px;
  height: 44px;
  background: $content_background_passive;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;

  &Active {
    background-color: $tortoise_main;

    > img {
      filter: invert(1);
    }
  }
}

.globalSymbolsSection {
  @include info;
  display: flex;
  grid-gap: 16px;
  align-items: center;
  justify-content: center;

  > button {
    display: flex;
    grid-gap: 16px;
    align-items: center;
    background: $content_background_passive;
    border: 1px solid $border_main;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 32px;
    cursor: pointer;
  }
}

.expansionSection {
  @include info;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  align-items: center;

  > div {
    display: flex;
    grid-gap: 8px;
    align-items: center;

    button {
      display: flex;
      min-width: 161px;
      grid-gap: 16px;
      align-items: center;
      justify-content: center;
      background: $content_background_passive;
      border: 1px solid $border_main;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 12px 32px;
      cursor: pointer;

      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }

      &:nth-child(1) {
        background: $tortoise_main;
      }

      &:nth-child(2) {
        background: $red_main;
      }
    }
  }
}

@mixin gridSection() {
  display: flex;
  grid-gap: 32px;
  flex-direction: column;
  background: $content_background_passive;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 32px;

  &Top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.totalsSection {
  @include gridSection;

  &Main {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 16px;
  }
}

.accountsSection {
  @include gridSection;

  &Main {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 80px;

    .accountContent {
      display: flex;
      flex-direction: column;
      background: $background_passive;
      border: 1px solid $border_main;
      box-sizing: border-box;
      border-radius: 24px;
      padding: 24px;
      min-width: 330px;
      cursor: pointer;

      &MultiSelect {
        border: 1px solid $tortoise_main;
      }

      hr {
        border: 1px solid $border_main;
        margin: 16px 0;
      }

      .accountInfo > div {
        display: flex;
        flex-direction: column;
        grid-gap: 8px;
        margin-bottom: 8px;

        .brain, .executor {
          display: flex;
          grid-gap: 8px;

          .alive {
            color: $tortoise_main;
          }

          .failure {
            color: $red_main;
          }
        }
      }

      .accountInfo {
        .multiSelect{
          margin-left: auto;
          width: 22px;
          height: 22px;
          background: $background_passive;
          border: 1px solid $border_main;
          box-sizing: border-box;
          border-radius: 6px;
          cursor: pointer;
        }

        .accountProfile {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > div {
            display: flex;
            grid-gap: 8px;
            align-items: center;
          }
        }
      }

      .accountInvestment > div {
        margin-bottom: 16px;

        .active {
          color: $tortoise_main;
        }

        .warning {
          color: $warning;
        }

        .failure {
          color: $red_main;
        }
      }
    }
  }

  &Actions {
    display: flex;
    grid-gap: 32px;
    align-items: center;

    > button {
      width: 44px;
      height: 44px;
      background: $background_passive;
      border: 1px solid $border_main;
      box-sizing: border-box;
      border-radius: 12px;
      cursor: pointer;

      img {
        width: 24px;
      }
    }
  }
}

//Called when trade is list
.accountsSectionList {
  padding: 0;

  .accountsSectionTop {
    padding: 32px 32px 0;
  }

  .accountsSectionMain {
    grid-gap: 0;
    flex-direction: column;

    .accountContent {
      border: 0;

      &MultiSelect {
        border: 1px solid $tortoise_main;
      }

      > div {
        display: flex;
        flex-direction: row;
      }

      .accountInfo {
        display: flex;
        flex-direction: column;

        > div {
          flex-direction: row;
          grid-gap: 24px;
        }

        .accountProfile {
          justify-content: flex-start;
          grid-gap: 32px;
        }
      }
    }

    .accountContent:nth-child(even) {
      background: $content_background_passive;
    }

    .accountInvestment {
      grid-gap: 80px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .dashboardWrapper {
    flex-direction: column;
    grid-gap: 8px;
    padding: 3vw;
  }
  .dashboardContent {
    > .header {
      cursor: pointer;

      h3 > img {
        display: initial;
      }

      .arrowUp {
        transform: rotate(180deg);
      }
    }
  }
  .dashboardMainInfo {
    display: none;

    &Show {
      display: flex;
    }
  }
}

@media screen and (max-width: 450px) {
  .dashboardWrapper {
    padding: 24px;
  }
  .dashboardContent {
    > .header {

      h3 {
        font-size: 30px;
      }

      .logout {
        position: absolute;
        right: 24px;
        top: 135px;
      }
    }
  }
  .expansionSection {
    width: 330px;

    > div {
      gap: 16px;

      button {
        min-width: 130px;
      }
    }
  }

  .accountsSection {
    &Main {
      .accountContent {
        min-width: 0;
      }
    }
  }
}
