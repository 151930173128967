@import "../../styles/colors";

.profileWrapper {
  display: flex;
  grid-gap: 72px;
  padding: 7vw 3vw;
}

hr {
  border: 1px solid $border_main;
  margin: 16px 0;
}

.profileContent {
  display: flex;
  flex-direction: column;
  flex-grow: 5;
  grid-gap: 32px;

  > .mainSection {
    display: flex;
    flex-direction: row;
    grid-gap: 32px;
  }

  .restart {
    margin-left: auto;
    button {
      display: flex;
      min-width: 161px;
      grid-gap: 16px;
      align-items: center;
      justify-content: center;
      background: $red_main;
      border: 1px solid $border_main;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 12px 32px;
      cursor: pointer;
      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }

  .logout {
    align-self: center;
    cursor: pointer;
  }

  .mobileHeader {
    display: none;
  }
}

@mixin info() {
  height: fit-content;
  width: 380px;
  min-height: 110px;
  background: $content_background_passive;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 24px 32px;
}

.harvesterHealth {
  @include info;
}

.harvesterHealthStatus {
  display: flex;
  align-items: center;

  &Active {
    color: $tortoise_main;
  }

  &Failure {
    color: $red_main;
  }
}

.harvesterHealthLevel {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  margin-left: 12px;

  &Active {
    background: $tortoise_main;
  }

  &Failure {
    background: $red_main;
  }
}

.progress {
  p {
    font-size: 16px;
  }
  .inProgress {
    color: $warning
  }
  .completed {
    color: $tortoise_main
  }
  .errored {
    color: $red_main
  }
}

.profileAndResults, .brainAndExecutor {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;

  .profile, .results, .brain, .executor {
    background: $content_background_passive;
    border: 1px solid $border_main;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 32px;
    position: relative;
  }

  .configSignalBtn {
    position: absolute;
    top: 42px;
    right: 32px;

    @media screen and (max-width: 450px) {
      padding: 6px 16px;
      right: 8px;
      h4 {
        font-size: 0.6em;
      }
    }
  }
}

.profile {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;

  > div {
    display: flex;
    justify-content: space-between;
    grid-gap: 32px;

    .profileInfo {
      display: flex;
      grid-gap: 12px;
      align-items: center;

      > img {
        width: 24px;
      }
    }
  }

  .profileInvestment {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;

    > div {
      display: flex;
      grid-gap: 8px;
    }

    .status {
      display: flex;
      grid-gap: 18px;

      p {
        font-weight: 700;
        align-self: center;
      }

      div {
        display: flex;
        align-items: center;
        grid-gap: 8px;

        .over {
          font-size: 16px;
          color: $red_main;
        }

        .normal {
          font-size: 16px;
          color: $tortoise_main;
        }
      }
    }
    .overInvestedCoins {
      flex-direction: column;
      margin-top: 16px;
      .columns, .coin {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        h3, h4, h5 {
          flex: 0 1 30%;
        }
      }
    }
    button {
      display: flex;
      grid-gap: 16px;
      align-self: self-end;
      align-items: center;
      background: $content_background_passive;
      border: 1px solid $border_main;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 12px 32px;
      cursor: pointer;
      margin-left: auto;
    }
  }
}

.globalSymbolsSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > button {
    display: flex;
    grid-gap: 16px;
    align-items: center;
    justify-content: center;
    background: $content_background_passive;
    border: 1px solid $border_main;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 32px;
    cursor: pointer;
    h5 {
      display: none;
    }
  }
}

.results {
  padding: 0 !important;

  > hr {
    margin: 16px 32px;
  }

  .resultsTimeSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 32px 32px 0 32px;

    .resultsTime {
      display: flex;
      flex-direction: column;
      grid-gap: 12px;
    }

    .refresh {
      display: flex;
      align-items: center;
      grid-gap: 11px;

      &Btn {
        width: 44px;
        height: 44px;
        background: $background_passive;
        border: 1px solid $border_main;
        box-sizing: border-box;
        border-radius: 12px;
        cursor: pointer;
      }
    }
  }

  p {
    font-weight: 700;
  }

  &Values {
    display: flex;
    flex-direction: column;

    .resultsValue {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 24px 32px;
      h5 {
        width: 45%;
      }

      &:nth-child(even) {
        background: $background_passive;
      }
    }
  }
}

.brain, .executor {
  &Content {
    display: flex;
    grid-gap: 12px;

    > img {
      width: 24px;
    }
  }

  p {
    font-weight: 700;
  }

  .futureAlg {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;

    .inputRow1 {
      display: flex;
      grid-gap: 16px;

      > div {
        width: 15%;
      }
    }

    .inputRow2, .inputRow3, .inputRow4 {
      display: flex;
      grid-gap: 16px;
      align-items: center;

      .selectWrapper {
        width: 47%;
      }

      .btnWrapper {
        display: flex;
        align-items: center;
      }
    }
  }
}

.executorInputs {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;

  .inputRow1 {
    display: flex;
    grid-gap: 16px;
    align-items: center;
    width: 50%;
  }

  .inputRow2, .inputRow3, .inputRow4 {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }
}

.saveBtn {
  width: 44px;
  height: 44px;
  background: $content_background_passive;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;

  &Active {
    background-color: $tortoise_main;

    > img {
      filter: invert(1);
    }
  }
}

@media screen and (max-width: 1100px) {
  .profileWrapper {
    flex-direction: column;
    grid-gap: 8px;
    padding: 3vw;
  }
  .profileContent {
    > .mainSection {
      flex-direction: column;
    }

    .mobileHeader {
      display: flex;
      width: fit-content;
      grid-gap: 12px;
      align-items: center;
      cursor: pointer;

      .arrowUp {
        transform: rotate(180deg);
      }
    }
  }
  .profileAndResults {
    order: 3;
  }
  .brainAndExecutor {
    display: none;

    &Show {
      display: flex;
      order: 2;
    }
  }
}

@media screen and (max-width: 767px) {
  .harvesterHealth {
    display: none;
  }
  .restart {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 450px) {
  .globalSymbolsSection {
    > button {
      padding: 8px;

      img {
        width: 12px;
      }

      h4 {
        display: none;
      }

      h5 {
        display: block;
      }
    }
  }

  .results {
    .resultsTimeSection {
      padding: 24px 24px 0 24px;

      .refresh {
        h4 {
          display: none;
        }

        &Btn {
          width: 32px;
          height: 32px;

          img {
            width: 12px;
          }
        }
      }
    }

    &Values {
      .resultsValue {
        flex-direction: column;
        grid-gap: 8px;
      }
    }
  }
}
