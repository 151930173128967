@import "../../styles/colors";
.harvesterModal {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  grid-gap: 16px;

  ul {
    width: 100%;
    li {
      list-style-type: none;
      text-align: start;
      margin: 22px 16px;
    }
  }
}