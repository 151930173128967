@import "../../styles/colors";

@mixin show() {
  &Show{
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    gap: 32px;
    background: rgba($background_passive, 0.8);
    z-index: 1;
    overflow: hidden;
  }
}

.sideNavbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoWrapper {
  width: 144px;
  cursor: pointer;

  > img {
    width: 100%;
  }
}

.navItem {
  width: 72px;
  height: 72px;
  background: $background_passive no-repeat center;
  background-size: 32px;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 32px;
  cursor: pointer;

  &Active {
    background-color: $background_active;
  }
}

.burgerWrapper {
  display: none;
}

@media screen and (max-width: 1100px) {
  .navbarWrapper {
    display: flex;
    justify-content: space-between;
  }
  .logoWrapper {
    width: 18vw;
  }
  .sideNavbar {
    display: none;
    @include show;
  }
  .burgerWrapper {
    width: 5vw;
    display: block;
    > img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 450px) {
  .navbarWrapper {
    display: flex;
    justify-content: space-between;
  }
  .logoWrapper {
    width: 30vw;
  }
  .sideNavbar {
    display: none;
    @include show;
  }
  .burgerWrapper {
    margin-top: 24px;
    width: 11vw;
    display: block;
    > img {
      width: 100%;
    }
  }
}
