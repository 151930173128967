@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');
@import "styles/colors";

* {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  font-weight: 400;
  padding: 0;
  color: $white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  padding: 0;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  overflow-y: scroll;
  touch-action: manipulation;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  word-wrap: break-word;
  overscroll-behavior-y: none;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


h1 {
  font-size: 72px;
  font-weight: 700;
  margin-bottom: 24px;
}

h2 {
  font-size: 44px;
  font-weight: 700;
}

h3 {
  font-size: 32px;
  font-weight: 700;
}

h4 {
  font-size: 16px;
  font-weight: 700;
}

h5 {
  font-size: 16px;
  font-weight: 400;
}

p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}

a {
  font-size: 16px;
  font-weight: 400;
  color: inherit;
  text-decoration: none;
  font-family: 'Montserrat';
}

button:focus {
  outline: none;
}

.floatingInputContainer {
  position: relative;
  min-width: 130px;
  padding: 8px 16px;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 12px;
  background: $background_passive;
  &Lighter {
    background: $content_background_passive;
  }
}

.floatingInputPlaceholder {
  position: absolute;
  top: 50%;
  transform: translate(5px, -50%);
  pointer-events: none;
  transition: all 0.14s ease-in-out;
  font-size: 16px;
}

.floatingInputContainer input[type=text].field-input {
  border: none;
  padding: 16px 6px 0;
  margin-top: 0px;
  font-size: 16px !important;
  display: block;
  box-sizing: border-box;
  width: 100%;
  bottom: 0px;
  background: $background_passive;
}

.floatingInputContainerLighter input[type=text].field-input {
  background-color: $content_background_passive;
}

.floatingInputContainer input[type=text].field-input.c-fix, input[type=text].field-input:focus, input[type=text].field-input:not(:placeholder-shown) {
  background-color: transparent;
}

.floatingInputContainer input[type=text].field-input.c-fix ~ label, input[type=text].field-input:focus ~ label, input[type=text].field-input:not(:placeholder-shown) ~ label {
  font-size: 10px;
  font-weight: 300;
  top: calc(30% - 6px);
  transform: translate(5px, 0%);
}

.floatingInputContainer input[type=text].field-input::-webkit-input-placeholder {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
}

.floatingInputContainer input[type=text].field-input::-moz-placeholder {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
}

.floatingInputContainer input[type=text].field-input:-ms-input-placeholder {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
}

.react-toggle {
  width: fit-content;
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 88px;
  height: 40px;
  padding: 0;
  border-radius: 30px;
  background-color: $red_background;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle--checked .react-toggle-track {
  background-color: $tortoise_background;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $tortoise_background;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 32px;
  height: 32px;
  border: 1px solid $border_main;
  border-radius: 50%;
  background-color: $red_main;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 52px;
  border-color: $border_main;
  background-color: $tortoise_main;
}

.react-select {
  &Padding {
    padding: 0 32px;
  }
  .react-select__control,
  .react-select__menu, {
    cursor: pointer;
    background: $content_background_passive;
    border: 1px solid $border_main;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 0;
  }
  .react-select__single-value {
    color: $white;
  }
  .react-select__menu-list {
    .react-select__option {
      cursor: pointer;
    }
    .react-select__option--is-selected {
      background-color: $background_passive;
    }
    .react-select__option--is-focused {
      background-color: $background_passive !important;
    }
  }
}

@media screen and (max-width: 1100px) {
  h1 {
    font-size: 8vw;
  }

  a {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 450px) {
  h1 {
    font-size: 10vw;
    margin-bottom: 4vw;
  }

  h5 {
    font-size: 16px;
  }

  p {
    font-size: 5.5vw;
  }


  a {
    font-size: 5vw;
  }

  .floatingInputContainer {
    padding: 8px 24px;
    min-width: 150px;
  }
  .floatingInputPlaceholder {
    font-size: 20px;
  }
  .floatingInputContainer input[type=text].field-input {
    padding-top: 15px;
    font-size: 16px !important;
  }
  .floatingInputContainer input[type=text].field-input.c-fix ~ label, input[type=text].field-input:focus ~ label, input[type=text].field-input:not(:placeholder-shown) ~ label {
    font-size: 12px;
    left: 19px;
    top: calc(30% - 4px);
  }
}
