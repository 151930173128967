@import "../../styles/colors";

.modalBackground {
  display: none;
  position: fixed;
  background: rgba(36, 36, 48, 0.9);
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  &Show {
    display: flex;
    overflow-y: scroll;
  }
}

.mainModal {
  padding: 32px;
  margin: auto;
  overflow-y: auto;
  width: fit-content;
  max-height: 70vh;

  background: #242430;
  border: 1px solid #50506B;
  box-shadow: 8px 8px 24px #242430;
  border-radius: 24px;
  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 24px;
    margin: 32px;
  }
}

@media screen and (max-width: 450px) {
  .modalBackground {
    padding: 32px;
  }
  .mainModal {
    padding: 24px;
    max-height: 90vh;
    overflow: auto;
    max-width: 100% !important;
  }
}
