@import "../../../styles/colors";

.sideSection {
  position: fixed;
  top: 0;
  right: 0;
  width: 80vw;
  height: 100%;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 4px;
  background: $content_background_passive;
  transition: all 1s ease;
  padding: 6vw;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  h3 {
    margin-bottom: 32px;
  }

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  &Closed {
    opacity: 0;
    transform: translate(100%, 0);
  }

  &Opened {
    opacity: 1;
    transform: translate(0, 0);
  }

  .closeBtn {
    position: absolute;
    top: 10vh;
    right: 1vw;
    width: fit-content;
    background: $content_background_passive;
    border: 1px solid $border_main;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 3px 16px;
    cursor: pointer;
    font-weight: bolder;
  }

  .accountProfile {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: $background_passive;
    border: 0;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 24px;
    justify-content: space-between;
    margin-bottom: 12px;
    > div {
      display: flex;
      grid-gap: 8px;
      align-items: center;
    }

    .inputRow {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    h4, h5 {
      flex: 2;
    }

    .coinLine {
      display: flex;
      gap: 8px;
    }
  }
}

.active {
  color: $tortoise_main
}

.warning {
  color: $warning
}

.error {
  color: $red_main
}

.button {
  width: fit-content;
  background: $content_background_passive;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  padding: 18px 56px;
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .accountProfile {
    .smallColumn {
      flex-direction: column;
      > div > div {
        flex-direction: column;
        align-items: flex-end;
        > div, > button {
          width: 150px;
          padding: 8px 8px;
        }
      }
    }
  }
  .sideSection {
    width: 100%;
  }
}

.datePicker {
  > div {
    display: flex;
    align-items: center;
  }
}

.timePicker {
  > div {
    border: none;
    svg {
      stroke: #ffffff;
    }
  }
}
