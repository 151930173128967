$white: #FFFFFF;
$black: #000000;
$tortoise_main: #39C7D1;
$tortoise_background: rgba(57, 199, 209, 0.3);
$red_main: #FF003D;
$red_background: rgba(255, 0, 61, 0.3);
$background_passive: #242430;
$content_background_passive: #2A2A39;
$background_active: #434359;
$border_main: #50506B;
$warning: #F8810A;
