.mainWrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  padding: 0 32px;

  button {
    background: #2A2A39;
    border: 1px solid #50506B;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 32px;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      -webkit-transition: opacity 0.25s;
      transition: opacity 0.25s;
    }
  }
}

.coinTable {
  display: flex;
  gap: 32px;

  p:first-child, div:first-child {
    flex: 2;
    padding-right: 0;
  }

  p:nth-child(2), div:nth-child(2),
  p:nth-child(3), div:nth-child(3),
  p:nth-child(4), div:nth-child(4) {
    flex: 1;
  }
  p:nth-child(5), div:nth-child(5) {
    flex: 3;
    text-align: center;
    align-self: center;
    justify-content: center;
  }

  p {
    text-align: left;
  }

  input {
    width: fit-content;
    padding: 0!important;
  }

  button {
    background: #2A2A39;
    border: 1px solid #50506B;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 32px;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      -webkit-transition: opacity 0.25s;
      transition: opacity 0.25s;
    }
  }
}
