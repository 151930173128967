@import "../../styles/colors";
.errorModal {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  grid-gap: 16px;

  button {
    display: flex;
    grid-gap: 16px;
    align-items: center;
    background: $content_background_passive;
    border: 1px solid $border_main;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 32px;
    cursor: pointer;
  }
}