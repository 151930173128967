@import "../../styles/colors";

.inputRow {
  display: flex;
  grid-gap: 16px;
  align-items: center;
  //width: 50%;
}

.btnWrapper {
  display: flex;
  align-items: center;
}

.saveBtn {
  width: 44px;
  height: 44px;
  background: $content_background_passive;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;

  &Active {
    background-color: $tortoise_main;

    > img {
      filter: invert(1);
    }
  }
}
