.mainWrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
}

.nowCheck {
  display: flex;
  grid-gap: 16px;
  align-items: center;
}

.refreshButton {
  display: flex;
  grid-gap: 16px;
  width: fit-content;
  background: #2A2A39;
  border: 1px solid #50506B;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 32px;
  align-self: flex-end;
  cursor: pointer;
}
