.usersWrapper {
  display: flex;
  grid-gap: 72px;
  padding: 7vw 3vw;

  .usersDash {
    display: flex;
    flex-direction: column;
    grid-gap: 72px;
    width: 100%;
    .header {
      display: flex;
      grid-gap: 8px;

      .logout {
        align-self: center;
        margin-left: auto;
        cursor: pointer;
      }
    }
    .usersContent {
      display: flex;
      flex-direction: column;
      flex-grow: 5;
      grid-gap: 32px;
    }
  }
}

.section {
  display: flex;
  grid-gap: 32px;
  flex-direction: column;
  background: #2A2A39;
  border: 1px solid #50506B;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 32px;

  .content {
    display: flex;
    flex-direction: column;
    background: #242430;
    border: 0;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 24px;
    min-width: 330px;

    .head {
      display: flex;
      margin-bottom: 16px;
    }

    h4:nth-child(1), h5:nth-child(1) {
      flex: 3;
    }

    h4, h5, .reactToggle {
      flex: 2 1;
      word-break: break-word;
    }

  }
}