@import "../../styles/colors";

.ordersWrapper {
  display: flex;
  grid-gap: 72px;
  padding: 7vw 3vw;
}

.ordersContent {
  display: flex;
  flex-direction: column;
  flex-grow: 5;
  grid-gap: 32px;

  > .header {
    display: flex;
    grid-gap: 8px;

    .logout {
      align-self: center;
      margin-left: auto;
      cursor: pointer;
    }
  }
}

@mixin gridSection() {
  display: flex;
  grid-gap: 32px;
  flex-direction: column;
  background: $content_background_passive;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 0 0 32px 0;

  &Top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 32px 32px 0;
  }
}

.totalsSection {
  @include gridSection;

  &Main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;

    .accountContent {
      display: flex;
      flex-direction: column;
      background: $background_passive;
      border: 0;
      box-sizing: border-box;
      border-radius: 24px;
      padding: 24px;
      min-width: 330px;

      hr {
        border: 1px solid $border_main;
        margin: 16px 0;
      }
    }

    .accountInfo {

      .accountProfile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 8px;

        > div {
          display: flex;
          grid-gap: 8px;
          align-items: center;
        }
      }
    }

    .ordersInfo {
      display: flex;
      margin-bottom: 16px;

      h4, h5 {
        flex: 2;
        word-break: break-word;
      }

      h4:nth-child(1), h5:nth-child(1) {
        flex: 1;
      }

      > .button {
        background: $content_background_passive;
        border: 1px solid $border_main;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 6px 16px;
        cursor: pointer;
      }
    }
  }

    .button {
      width: fit-content;
      height: fit-content;
      background: $content_background_passive;
      border: 1px solid $border_main;
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      padding: 18px 56px;
      cursor: pointer;
    }

}

@media screen and (max-width: 1100px) {
  .ordersWrapper {
    flex-direction: column;
    grid-gap: 8px;
    padding: 3vw;
  }
  .ordersContent {
    > .header {
      cursor: pointer;

      h3 > img {
        display: initial;
      }

      .arrowUp {
        transform: rotate(180deg);
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .ordersWrapper {
    padding: 24px;
  }
  .ordersContent {
    > .header {
      .logout {
        position: absolute;
        right: 24px;
        top: 135px;
      }
    }
  }
}
