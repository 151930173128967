@import "styles/colors";

.App {
  min-height: 100vh;
  background: $background_passive;
}

.button {
  display: flex;
  min-width: 161px;
  grid-gap: 16px;
  align-items: center;
  justify-content: center;
  background: $content_background_passive;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 32px;
  cursor: pointer;

  @media screen and (max-width: 1100px){
    min-width: 72px;
    //padding: 4px 12px;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &Procced {
    background: $tortoise_main;
  }

  &Cautious {
    background: $red_main;
  }
}
