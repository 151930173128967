.mainWrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
}

.header {
  display: flex;
  grid-gap: 16px;
}

.harvesterList {
  display: flex;
  grid-gap: 32px;
  justify-content: space-between;
  > div {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    background: #2A2A39;
    border: 1px solid #50506B;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 24px;
  }
}

.subHeader {
  display: flex;
  justify-content: space-between;
  .syncBtn {
    width: 32px;
    background: #FF003D;
    border: 1px solid #50506B;
    box-sizing: border-box;
    border-radius: 8px;
  }
}

.inputContainer {
  display: flex;

  input[type=text].fieldInput {
    padding-top: 0;
    ::placeholder {
      display: initial;
    }
  }
}
.coinsWrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  max-height: 15vw;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    width: 4px;
    background-color: #2A2A39;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    width: 4px;
    border-radius: 3px;
  }
  .coinsElement {
    display: flex;
    justify-content: space-between;
    div {
      width: 45%;
    }
    .coinsOrigin {
      width: 20%;
    }
    button {
      width: 13%;
      white-space: normal;
      appearance: none;
      background: none;
      border: none;
      align-items: center;
      display: flex;
      cursor: pointer;
    }
    img {
      width: 14px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
}

.bottomButtons{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 48px;
  a {
    cursor: pointer;
  }
  > div {
    background: #2A2A39;
    border: 1px solid #50506B;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 32px ;
  }
}

@media screen and (max-width: 450px){
  .harvesterList {
    flex-direction: column;
    > div {
      padding: 12px;
    }
  }
  .coinsWrapper {
    min-height: 120px;
  }
  .bottomButtons{
    grid-gap: 24px;
  }
}
