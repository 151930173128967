@import "../../styles/colors";

.loginWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10vw;
}

.logo {
  width: 100%;
  max-width: 380px;
}

.brandName {
  background: linear-gradient(90.96deg, #FF0055 0%, #F79508 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 44px;
}

.buttonWrapper {
  width: fit-content;
  background: $content_background_passive;
  border: 1px solid $border_main;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  padding: 18px 56px;
  margin: auto;
  cursor: pointer;

  > img {
    width: 32px;
  }

  > span {
    font-size: 24px;
    margin-left: 16px;
    color: $white;
  }
}

@media screen and (max-width: 450px) {
  .loginWrapper {
    padding-top: 35vw;
  }

  .buttonWrapper {
    > img {
      width: 24px;
    }

    > span {
      font-size: 16px;
    }
  }
}